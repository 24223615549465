export function capitalize(string: string): string {
  return string[0].toUpperCase() + string.slice(1);
}

export function debounce(fn: Function, time: number) {
  let timeoutId: NodeJS.Timeout | null = null;
  return wrapper;
  function wrapper(...args: any) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      timeoutId = null;
      fn(...args);
    }, time);
  }
}
