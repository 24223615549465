import React from "react";
import { faChevronCircleRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Carousel, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import useTitle from "../utils/useTitle";

export default function Home() {
  useTitle("Home");
  return (
    <>
      <Carousel className="home-carousel" interval={10000}>
        <Carousel.Item id="item-1">
          <Container>
            <h1 className="animate__animated animate__zoomInDown animate__delay-2s">
              So Easy to Use
            </h1>
            <h3 className="animate__animated animate__zoomInLeft animate__delay-3s">
              Never forget a subscription again!
            </h3>

            <ul className="list-unstyled">
              <li className="animate__animated animate__slideInLeft animate__delay-4s">
                <FontAwesomeIcon
                  icon={faChevronCircleRight}
                  fixedWidth={true}
                />
                <span>Simple</span>
              </li>
              <li className="animate__animated animate__slideInLeft animate__delay-5s">
                <FontAwesomeIcon
                  icon={faChevronCircleRight}
                  fixedWidth={true}
                />
                <span>Use it anywhere</span>
              </li>
              <li className="animate__animated animate__slideInLeft animate__delay-6s">
                <FontAwesomeIcon
                  icon={faChevronCircleRight}
                  fixedWidth={true}
                />
                <span>Works when you need it</span>
              </li>
              <li className="animate__animated animate__slideInLeft animate__delay-7s">
                <FontAwesomeIcon
                  icon={faChevronCircleRight}
                  fixedWidth={true}
                />
                <span>Information at a glance</span>
              </li>
            </ul>
            <Link
              to="/about"
              className="btn btn-primary animate__animated animate__zoomInDown animate__delay-8s"
            >
              Learn More
            </Link>
          </Container>
        </Carousel.Item>
        <Carousel.Item id="item-2">
          <Container>
            <Row>
              <Col sm={6} className="offset-sm-3">
                <ul className="list-unstyled">
                  <li className="animate__animated animate__zoomInLeft animate__delay-2s">
                    <span>Use On Any Device</span>
                  </li>
                  <li className="animate__animated animate__zoomInLeft animate__delay-3s">
                    <span>Never Lose Track Of Subscriptions</span>
                  </li>
                  <li className="animate__animated animate__zoomInLeft animate__delay-4s">
                    <span>Save Time and Money</span>
                  </li>
                </ul>
                <Link
                  to="/about"
                  className="btn btn-primary animate__animated animate__zoomInDown animate__delay-5s"
                >
                  Learn More
                </Link>
              </Col>
            </Row>
          </Container>
        </Carousel.Item>
        <Carousel.Item id="item-3">
          <Container>
            <Row>
              <Col sm={6} className="offset-sm-3">
                <h1 className="animate__animated animate__zoomInDown animate__delay-2s">
                  No Credit Cards Needed!
                </h1>
                <h3 className="animate__animated animate__zoomInDown animate__delay-3s">
                  Use for free!
                </h3>
                <p className="animate__animated animate__zoomInLeft animate__delay-4s">
                  We know the pain of keeping track of subscriptions and we hope
                  our software helps you keep all your information in one place
                  instead of all over the web. Best of all, our service is free
                  to use, saving you money!
                </p>
                <Link
                  to="/about"
                  className="btn btn-primary animate__animated animate__zoomInDown animate__delay-5s"
                >
                  Learn More
                </Link>
              </Col>
            </Row>
          </Container>
        </Carousel.Item>
      </Carousel>
    </>
  );
}
