import { useState, useEffect, useCallback, useContext } from "react";
import axios, { AxiosRequestConfig } from "axios";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../components/AuthContext";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

interface RequestProps<T> {
  params: AxiosRequestConfig<any>;
  processData?: (data: any) => T;
}

export const useApi = <T>({ params, processData }: RequestProps<T>) => {
  // Response state
  const [data, setData] = useState<T>();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>();
  const [state, dispatch] = useContext(AuthContext);
  const navigate = useNavigate();

  // Turn objects into strings for useCallback & useEffect dependencies
  const stringifiedParams = JSON.stringify(params);

  // If no processing function is passed just cast the object to type T
  // The callback hook ensures that the function is only created once
  // and hence the effect hook below doesn't start an infinite loop

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const processJson = useCallback(
    processData || ((jsonBody: any) => jsonBody as T),
    []
  );

  useEffect(() => {
    let isSubscribed = true;

    // Define asynchronous function
    const fetchApi = async () => {
      try {
        params.headers = {
          ...params.headers,
          Authorization: `Bearer ${state.user?.providerId}`,
        };

        // Fetch data from REST API
        const response = await axios.request(params);

        if (isSubscribed) {
          if (response.status === 200) {
            // Extract json
            const rawData: any = await response.data;
            const processedData = processJson(rawData);
            setData(processedData);
          } else if (response.status === 401) {
            dispatch({ loggedIn: false, user: null });
            navigate("/login");
          } else {
            console.error(`Error ${response.status} ${response.statusText}`);
          }
        }
      } catch (error: any) {
        console.error(error);
        if (error.isAxiosError && error.response.status === 401) {
          dispatch({ loggedIn: false, user: null });
          navigate("/login", { state: { message: "You are not logged in." } });
        }
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    // Call async function
    fetchApi();

    return () => {
      isSubscribed = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifiedParams, processJson]);

  return { data, loading, error };
};
