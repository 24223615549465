import {
  faExclamationTriangle,
  faPlus,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { format } from "date-fns";
import { useContext, useState } from "react";
import { Alert, Container, Table } from "react-bootstrap";

import { AuthContext } from "../components/AuthContext";
import useSortableData from "../utils/useSortableData";
import { getDate, dateOverdue } from "../utils/dateHelpers";
import { useNavigate } from "react-router";
import useTitle from "../utils/useTitle";
import { Link, useLocation } from "react-router-dom";
import { useApi } from "../utils/useApi";

export class Subscription {
  id?: string;
  name!: string;
  description?: string;
  price?: number;
  company?: string;
  startDate?: Date;
  nextDueDate?: Date;
  terminationDate?: Date;
  notes?: string;
  frequencyTime?: number;
  frequency?: Frequency;
  frequencyId?: string;
  userId?: string;
}
export class Frequency {
  constructor(id: string, name: string, code: string, order: number) {
    this.id = id;
    this.name = name;
    this.code = code;
    this.order = order;
  }
  id!: string;
  name!: string;
  code!: string;
  order!: number;
  getShortName = () => {
    return this.name.toLowerCase().replace(/[()]/g, "");
  };
}

function Subscriptions() {
  useTitle("Subscriptions");
  const location = useLocation();
  const messageState = location.state as {
    message: string;
  };
  const [showAlert, setShowAlert] = useState(
    messageState && messageState?.message !== undefined
  );
  const navigate = useNavigate();
  const { data: subscriptions, loading } = useApi<Subscription[]>({
    params: {
      url: "/subscriptions",
    },
    processData: (data) => {
      return data.data.subscriptions;
    },
  });
  const { items, requestSort, sortConfig } = useSortableData(subscriptions, {
    key: "nextDueDate",
    direction: "ascending",
    type: "date",
  });
  const [state] = useContext(AuthContext);

  const getClassNamesFor = (name: string) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  if (!state.loggedIn) {
    return <Container>You are not logged in.</Container>;
  }

  if (loading) {
    return <Container>Loading...</Container>;
  }

  return (
    <Container>
      {showAlert && messageState?.message && (
        <Alert dismissible={true} onClose={() => setShowAlert(false)}>
          {messageState.message}
        </Alert>
      )}
      <Link
        to="/subscriptions/new"
        className="btn btn-primary float-end text-white"
      >
        <FontAwesomeIcon icon={faPlus} />
      </Link>

      <h1>Subscriptions</h1>
      <Table striped hover>
        <thead>
          <tr>
            <th
              className={classNames("sortable name", getClassNamesFor("name"))}
              onClick={() => requestSort("name")}
            >
              Name
            </th>
            <th
              className={classNames(
                "d-none d-sm-table-cell sortable company",
                getClassNamesFor("company")
              )}
              onClick={() => requestSort("company")}
            >
              Company
            </th>
            <th
              className={classNames(
                "sortable price",
                getClassNamesFor("price")
              )}
              onClick={() => requestSort("price", "number")}
            >
              Price
            </th>
            <th
              className={classNames(
                "sortable next-due-date",
                getClassNamesFor("nextDueDate")
              )}
              onClick={() => {
                requestSort("nextDueDate", "date");
              }}
            >
              Due
            </th>
            <th className="overdue">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {items.length === 0 && (
            <tr>
              <td colSpan={5}>No subscriptions found</td>
            </tr>
          )}

          {items.length > 0 &&
            items.map((sub: Subscription) => (
              <tr
                onClick={() => {
                  navigate(`/subscriptions/${sub.id}`);
                }}
                key={sub.id}
                className={dateOverdue(sub?.nextDueDate) ? "table-danger" : ""}
              >
                <td>{sub.name}</td>
                <td className="d-none d-sm-table-cell">{sub.company}</td>
                <td>{sub.price}</td>
                <td>
                  {sub.nextDueDate
                    ? format(getDate(sub.nextDueDate), "MM/dd/yyyy")
                    : ""}
                </td>
                <td>
                  {dateOverdue(sub?.nextDueDate) ? (
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      className="text-danger"
                      title={
                        dateOverdue(sub?.nextDueDate)
                          ? "Subscription is overdue"
                          : ""
                      }
                    />
                  ) : (
                    ""
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default Subscriptions;
