import {
  faAlignJustify,
  faExclamationTriangle,
  faPencil,
  faSync,
  faTrashAlt,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { add, format } from "date-fns";
import { useContext, useState } from "react";
import { Alert, Button, ButtonGroup, Container, Modal } from "react-bootstrap";

import "react-day-picker/dist/style.css";
import { Link, useNavigate, useParams } from "react-router-dom";

import { AuthContext } from "../components/AuthContext";
import SubscriptionDetails from "../components/SubscriptionDetails";
import { DATEFORMAT, getDate } from "../utils/dateHelpers";
import { useApi } from "../utils/useApi";
import useTitle from "../utils/useTitle";
import { Frequency, Subscription } from "./Subscriptions";
import callSubscriptionApi from "../utils/callSubscriptionApi";
import DayPickerInput from "../components/DayPickerInput";

function SubscriptionDetail() {
  useTitle("Subscription Details");
  const navigate = useNavigate();
  const [state, dispatch] = useContext(AuthContext);
  const { id } = useParams();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRenewalModal, setShowRenewalModal] = useState(false);
  const [nextDueDate, setNextDueDate] = useState<Date | undefined>(undefined);
  const [message, setMessage] = useState<string | undefined>(undefined);

  const handleDelete = async () => {
    try {
      await callSubscriptionApi(
        {
          url: `/subscriptions/${id}`,
          method: "DELETE",
        },
        state,
        dispatch,
        navigate
      );
      navigate("/subscriptions", {
        state: { message: "Subscription successfully deleted." },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getNextDueDate = () => {
    const frequency = subscription?.frequency;
    const frequencyTime = subscription?.frequencyTime;

    if (!frequency || !nextDueDate || !frequencyTime) {
      return new Date();
    }
    const frequencyName = frequency.getShortName();

    const nextDate = add(nextDueDate, {
      [frequencyName]: frequencyTime,
    });
    return nextDate;
  };

  const handleRenewal = async () => {
    const nextDate = getNextDueDate();
    const modelToSave = {
      nextDueDate: nextDate,
      id: subscription?.id,
      name: subscription?.name,
      userId: state.user?.id,
    };

    try {
      const result = await callSubscriptionApi(
        {
          url: `/subscriptions/${id}`,
          method: "PATCH",
          data: modelToSave,
        },
        state,
        dispatch,
        navigate
      );
      setMessage("Subscription successfully renewed.");
      setShowRenewalModal(false);
      if (subscription) {
        subscription.nextDueDate = nextDate;
      }

      setNextDueDate(getDate(result.nextDueDate));
    } catch (error: any) {
      console.error(error);
    }
  };

  const { data: subscription, loading } = useApi<Subscription>({
    params: {
      url: `/subscriptions/${id}`,
    },
    processData: (data) => {
      const frequency = data.data.subscription.frequency;
      const modelToSet: Subscription = {
        ...data.data.subscription,
        frequency: new Frequency(
          frequency.id,
          frequency.name,
          frequency.code,
          frequency.order
        ),
      };

      if (data.data.subscription.nextDueDate) {
        setNextDueDate(getDate(data.data.subscription.nextDueDate));
      }

      return modelToSet;
    },
  });

  if (!state.loggedIn) {
    return <Container>You are not logged in</Container>;
  }

  if (loading) {
    return <Container>Loading...</Container>;
  }

  return (
    <Container>
      <h1>Subscription Details</h1>
      {message && (
        <Alert dismissible={true} onClose={() => setMessage("")}>
          {message}
        </Alert>
      )}
      <ButtonGroup className="float-end" vertical>
        <Link
          to="/subscriptions"
          aria-label="Subscriptions List"
          className="btn btn-outline-secondary btn-lg"
        >
          <FontAwesomeIcon icon={faAlignJustify} />
        </Link>
        <Link
          aria-label="Edit Subscription"
          to={`/subscriptions/${id}/edit`}
          className="btn btn-outline-secondary btn-lg"
        >
          <FontAwesomeIcon icon={faPencil} />
        </Link>
        <Button
          variant="info"
          className="btn-lg"
          aria-label="Renew Subscription"
          onClick={() => setShowRenewalModal(true)}
          disabled={
            !nextDueDate ||
            !subscription?.frequencyTime ||
            !subscription?.frequency ||
            !subscription?.nextDueDate
          }
        >
          <FontAwesomeIcon icon={faSync} color="white" />
        </Button>
        <Button
          variant="danger"
          className="btn-lg"
          aria-label="Delete Subscription"
          onClick={() => setShowDeleteModal(true)}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
      </ButtonGroup>
      <SubscriptionDetails subscription={subscription} />

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            color="red"
            size="3x"
            className="float-start me-2"
          />
          Are you sure? All data will be deleted. This cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            No
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showRenewalModal} onHide={() => setShowRenewalModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Renewal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Did your renewal start from the date listed below? Otherwise, pick a
            different date from the selector.
          </p>
          <label htmlFor="renewal-date">Renewal Date</label>
          <DayPickerInput
            classNames={{
              container: "w-100",
            }}
            inputProps={{
              className: "form-control",
              readOnly: true,
              id: "renewal-date",
            }}
            dateFormat={DATEFORMAT}
            placeholder="YYYY-MM-DD"
            defaultValue={nextDueDate ? getDate(nextDueDate) : undefined}
            onDayChange={(date) => {
              console.log(date);
              setNextDueDate(date);
            }}
          />
          <small className="form-text text-muted d-block">
            The date you renewed or will renew your subscription.
          </small>
          <small className="mt-3 d-block">
            When clicking <strong>Renew</strong>, it will advance your next due
            date by{" "}
            <strong>
              {subscription?.frequencyTime} {subscription?.frequency?.name}
            </strong>{" "}
            to{" "}
            <strong>{format(getDate(getNextDueDate()), "MM/dd/yyyy")}</strong>.
            If this is incorrect, please cancel this modal and modify the
            subscription frequency.
          </small>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowRenewalModal(false)}
          >
            Cancel
          </Button>
          <Button aria-label="Renew Button" onClick={handleRenewal}>
            Renew
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default SubscriptionDetail;
