import React from "react";
import loader from "../assets/loader.png";

function Loader() {
  return (
    <div className="loader">
      <div className="d-flex justify-content-center">
        <img src={loader} alt="Loading" />
      </div>
    </div>
  );
}

export default Loader;
