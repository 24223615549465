import axios, { AxiosRequestConfig } from "axios";
import { State } from "../components/AuthContext";
import { NavigateFunction } from "react-router-dom";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const callSubscriptionApi = async (
  params: AxiosRequestConfig<any>,
  state: State,
  dispatch: React.Dispatch<Partial<State>>,
  navigate: NavigateFunction
) => {
  try {
    params.headers = {
      ...params.headers,
      Authorization: `Bearer ${state.user?.providerId}`,
    };
    const response = await axios.request(params);
    if (response.status === 200 || response.status === 201) {
      // Extract json
      const rawData: any = await response.data;
      const processedData = rawData.data.subscription;
      return processedData;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401) {
      dispatch({ loggedIn: false, user: null });
      navigate("/login", {
        state: { message: "You are not logged in." },
      });
    } else {
      console.error(`Error ${response.status} ${response.statusText}`);
      throw new Error(`Error ${response.status} ${response.statusText}`);
    }
  } catch (error: any) {
    if (error.isAxiosError && error.response.status === 401) {
      dispatch({ loggedIn: false, user: null });
      navigate("/login", { state: { message: "You are not logged in." } });
    }
    throw new Error(`Error ${error}`);
  }
};

export default callSubscriptionApi;
