import {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import "react-day-picker/dist/style.css";
import { format, isValid, parseISO } from "date-fns";
import FocusTrap from "focus-trap-react";
import { ActiveModifiers, DayPicker } from "react-day-picker";
import { usePopper } from "react-popper";
import { useOnClickOutside } from "../utils/useOnClickOutside";
import { RefCallBack } from "react-hook-form";

export type DayPickerInputProps = {
  classNames: { container?: string };
  inputProps: InputHTMLAttributes<HTMLInputElement> & {
    ref?: RefCallBack;
  };
  dateFormat: string;
  placeholder: string;
  defaultValue?: Date;
  watchedValue?: Date;
  onDayChange?: (day: Date) => void;
};

export default function DayPickerInput({
  classNames,
  inputProps,
  dateFormat,
  placeholder,
  defaultValue,
  onDayChange,
  watchedValue,
}: DayPickerInputProps) {
  const [selected, setSelected] = useState<Date | undefined>();
  const [inputValue, setInputValue] = useState<string>("");
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const popperRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const calendarRef = useRef<HTMLDivElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { ref, onChange, onBlur, name, ...rest } = inputProps;

  const popper = usePopper(popperRef.current, popperElement, {
    placement: "top-start",
  });

  const closePopper = useCallback(() => {
    setIsPopperOpen(false);
  }, []);

  const handleButtonClick = () => {
    setIsPopperOpen(!isPopperOpen);
  };

  const handleDaySelect = (date: Date) => {
    setSelected(date);
    if (date) {
      setInputValue(format(date, dateFormat));
      if (onDayChange) {
        onDayChange(date);
      }
      closePopper();
    } else {
      setInputValue("");
    }
  };

  useOnClickOutside(calendarRef, closePopper);

  // Default value handling
  useEffect(() => {
    if (defaultValue) {
      setSelected(defaultValue);
      setInputValue(format(defaultValue, dateFormat));
    }
  }, [defaultValue, dateFormat]);

  // Watched value handling
  useEffect(() => {
    if (!watchedValue) {
      return;
    }
    let date = watchedValue;

    if (!isValid(date)) {
      date = parseISO(date.toString());
    }

    setSelected(date);
    setInputValue(format(date, dateFormat));
  }, [watchedValue, dateFormat]);

  return (
    <>
      <div ref={popperRef} className={`form-control ${classNames.container}`}>
        <div className="input-group">
          <input
            type="text"
            placeholder={placeholder}
            value={inputValue}
            {...rest}
            onClick={handleButtonClick}
          />
          <button
            ref={buttonRef}
            type="button"
            className="btn btn-primary"
            aria-label="Pick a date"
            onClick={handleButtonClick}
          >
            <span role="img" aria-label="calendar icon">
              📅
            </span>
          </button>
        </div>
      </div>
      {isPopperOpen && (
        <FocusTrap active={true}>
          <div
            tabIndex={-1}
            style={popper.styles.popper}
            className="dialog-sheet bg-white rounded-3 shadow-lg z-1000"
            {...popper.attributes.popper}
            ref={setPopperElement}
            role="dialog"
          >
            <div ref={calendarRef} className="py-2">
              <DayPicker
                initialFocus={isPopperOpen}
                mode="single"
                defaultMonth={selected}
                selected={selected}
                onSelect={(
                  _day: Date | undefined,
                  selectedDay: Date,
                  _activeModifiers: ActiveModifiers
                ) => {
                  handleDaySelect(selectedDay);
                }}
              />
            </div>
          </div>
        </FocusTrap>
      )}
    </>
  );
}
