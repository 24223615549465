import {
  faCalendar,
  faChartLineDown,
  faEnvelope,
  faGlobeAmericas,
  faHouse,
  faInfoSquare,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Nav } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import classNames from "classnames";
import { useContext } from "react";

import { AuthContext } from "./AuthContext";
import logo from "../assets/logo.png";

function Sidebar({ isOpen, toggle }: { isOpen: boolean; toggle: () => void }) {
  const [state] = useContext(AuthContext);
  return (
    <Col
      xl={2}
      className={classNames("sidebar col-auto px-sm-2 px-0 bg-dark", {
        "is-open": isOpen,
      })}
    >
      <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
        <Link
          to="/"
          className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none"
        >
          <img
            src={logo}
            alt="logo"
            style={{ height: "auto", width: "160px" }}
          />
        </Link>

        <Nav
          variant="pills"
          defaultActiveKey="/"
          className="d-flex align-items-start"
        >
          <Nav.Item className="w-100">
            <Nav.Link end={true} as={NavLink} to="/" onClick={toggle}>
              <FontAwesomeIcon icon={faHouse} className="me-2" />
              Home
            </Nav.Link>
          </Nav.Item>

          {state.loggedIn && (
            <>
              <Nav.Item className="w-100">
                <Nav.Link as={NavLink} to="/subscriptions" onClick={toggle} end>
                  <FontAwesomeIcon icon={faGlobeAmericas} className="me-2" />
                  Subscriptions
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="w-100">
                <Nav.Link as={NavLink} to="/calendar" onClick={toggle}>
                  <FontAwesomeIcon icon={faCalendar} className="me-2" />
                  Calendar
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="w-100">
                <Nav.Link as={NavLink} to="/dashboard" onClick={toggle}>
                  <FontAwesomeIcon icon={faChartLineDown} className="me-2" />
                  Dashboard
                </Nav.Link>
              </Nav.Item>
            </>
          )}

          <Nav.Item className="w-100">
            <Nav.Link as={NavLink} to="/about" onClick={toggle}>
              <FontAwesomeIcon icon={faInfoSquare} className="me-2" />
              About
            </Nav.Link>
          </Nav.Item>

          <Nav.Item className="w-100">
            <Nav.Link as={NavLink} to="/contact" onClick={toggle}>
              <FontAwesomeIcon icon={faEnvelope} className="me-2" />
              Contact
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
    </Col>
  );
}

export default Sidebar;
