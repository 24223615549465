import classNames from "classnames";
import React from "react";
import { FieldValues, Path, UseFormRegister } from "react-hook-form";

type SelectProps<T extends FieldValues> = {
  id: Path<T>;
  label: string;
  register: UseFormRegister<T>;
  required: boolean;
  defaultValue?: string;
  invalid?: boolean;
  helpText?: string;
  options: { key: string; value: string }[];
  className?: string;
  onChange?: (value: string) => void;
};

export default class Select<T extends FieldValues> extends React.Component<
  SelectProps<T>
> {
  render() {
    return (
      <div className={classNames("mb-3", this.props.className)}>
        <div className="form-floating">
          <select
            className={`form-control ${this.props.invalid ? "is-invalid" : ""}`}
            id={this.props.id}
            {...this.props.register(this.props.id, {
              required: this.props.required,
            })}
            value={this.props.defaultValue}
            onChange={(e) => {
              this.props.onChange && this.props.onChange(e.target.value);
            }}
          >
            <option value="">Choose One</option>
            {this.props.options.map((option) => (
              <option key={option.key} value={option.key}>
                {option.value}
              </option>
            ))}
          </select>

          {this.props.invalid && (
            <div className="invalid-feedback">
              {this.props.label} is required
            </div>
          )}

          <label htmlFor={this.props.id}>{this.props.label}</label>
        </div>
        {this.props.helpText && (
          <small className="form-text text-muted">{this.props.helpText}</small>
        )}
      </div>
    );
  }
}
