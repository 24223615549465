import classNames from "classnames";
import React from "react";
import { FieldValues, Path, UseFormRegister } from "react-hook-form";

import { DATEFORMAT } from "../utils/dateHelpers";
import DayPickerInput from "./DayPickerInput";

type DatePickerProps<T extends FieldValues> = {
  id: Path<T>;
  label: string;
  register: UseFormRegister<T>;
  required: boolean;
  autoComplete?: string;
  defaultValue?: Date;
  invalid?: boolean;
  helpText?: string;
  className?: string;
  inputContainerClassName?: string;
  watchedValue?: Date;
  onDayChange?: (day: Date) => void;
};

export default class DatePicker<T extends FieldValues> extends React.Component<
  DatePickerProps<T>
> {
  render() {
    return (
      <div className={classNames("mb-3", this.props.className)}>
        <div className="form-floating">
          <DayPickerInput
            classNames={{
              container: `${this.props.inputContainerClassName} ${
                this.props.invalid ? "is-invalid" : ""
              }`,
            }}
            inputProps={{
              id: this.props.id,
              className: "form-control",
              ...this.props.register(this.props.id, {
                required: this.props.required,
              }),
              readOnly: true,
            }}
            dateFormat={DATEFORMAT}
            placeholder="YYYY-MM-DD"
            defaultValue={this.props.defaultValue}
            onDayChange={this.props.onDayChange}
            watchedValue={this.props.watchedValue}
          />

          {this.props.invalid && (
            <div className="invalid-feedback">
              {this.props.label} is required
            </div>
          )}
          <label htmlFor={this.props.id}>{this.props.label}</label>
        </div>
        {this.props.helpText && (
          <small className="form-text text-muted w-100">
            {this.props.helpText}
          </small>
        )}
      </div>
    );
  }
}
