import { formatDistance } from "date-fns";
import { useContext, useEffect, useState } from "react";
import { Alert, Card, Container, Nav, Tab, Button } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router";

import { AuthContext, User } from "../components/AuthContext";
import Input from "../components/Input";
import useTitle from "../utils/useTitle";

function Account() {
  useTitle("Account");
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [state, dispatch] = useContext(AuthContext);
  const [alert, setAlert] = useState<string>("");
  const [error, setError] = useState<string>("");
  const {
    register,
    formState: { errors: userErrors },
    handleSubmit,
    reset,
    formState,
  } = useForm<User>();

  const updateUser = async (formResults: {
    name: string;
    email: string;
    password?: string;
  }) => {
    try {
      const results = await fetch(`${apiUrl}/users/${state.user?.id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${state.user?.providerId}`,
        },
        body: JSON.stringify(formResults),
      });

      if (results.status === 200) {
        return results.json();
      }
      setError("Could not update user");
    } catch (error) {
      console.log(error);
      setError("Could not update user");
    }
  };

  const onSubmit: SubmitHandler<User> = async (results, e) => {
    let logout = false;
    setAlert("");
    setError("");

    try {
      if (results.password !== results.passwordConfirmation) {
        setError("Passwords do not match.");
        return;
      }

      let formResults: any = {
        id: state.user?.id,
        name: results.name,
        email: results.email,
      };

      if (
        results.password &&
        results.passwordConfirmation &&
        results.password === results.passwordConfirmation
      ) {
        formResults.password = results.password;
        logout = true;
      }

      const updateResults = await updateUser(formResults);

      if (!updateResults?.data?.user) {
        return;
      }

      if (updateResults.data.user.email !== state.user?.email || logout) {
        dispatch({ loggedIn: false, user: null });
        navigate("/login");
        return;
      }

      setAlert("Account updated successfully.");
      dispatch({
        loggedIn: true,
        user: {
          email: updateResults.data.user.email,
          name: updateResults.data.user.name,
        },
      });
      reset();
    } catch (error) {
      setError("Could not update user");
      console.log(error);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAlert("");
    }, 5000);

    return () => clearTimeout(timeout);
  }, [alert]);

  if (state.loggedIn === false || state.user === null) {
    return <Container>You must be logged in to view this page.</Container>;
  }

  return (
    <Container>
      <h1>Account</h1>
      <h3>👋 Welcome, {state.user?.name}</h3>

      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Nav variant="pills" className="my-3">
          <Nav.Item>
            <Nav.Link eventKey="first">Overview</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="second">Edit Account</Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="first">
            <h4>Overview</h4>
            <Card>
              <Card.Body>
                <strong>Name:</strong> {state.user.name}
                <br />
                <strong>Email:</strong> {state.user.email}
                <br />
                <strong>
                  You joined{" "}
                  {formatDistance(new Date(state.user.createdAt!), new Date(), {
                    addSuffix: true,
                  })}
                </strong>
              </Card.Body>
            </Card>
          </Tab.Pane>
          <Tab.Pane eventKey="second">
            <h4>Edit Account</h4>
            {error && <Alert variant="danger">{error}</Alert>}
            {alert && <Alert variant="success">{alert}</Alert>}
            <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
              <Card>
                <Card.Header>Change Profile</Card.Header>
                <Card.Body>
                  <Input<User>
                    id="name"
                    label="Name"
                    register={register}
                    required={true}
                    defaultValue={state.user.name}
                    invalid={!!userErrors.name}
                  />
                </Card.Body>
              </Card>

              <Card className="mt-3">
                <Card.Header>Change Credentials</Card.Header>
                <Card.Body>
                  <p className="fw-bold">
                    Note: Changing your credentials below will log you out.
                  </p>

                  <Input<User>
                    id="email"
                    label="Email"
                    register={register}
                    required={true}
                    defaultValue={state.user.email}
                    invalid={!!userErrors.email}
                    type="email"
                    helpText="This is your username"
                  />

                  <Input<User>
                    id="password"
                    label="Password"
                    register={register}
                    required={false}
                    type="password"
                    autoComplete="new-password"
                    helpText="Leave blank to keep your current password"
                  />

                  <Input<User>
                    id="passwordConfirmation"
                    label="Password Confirmation"
                    register={register}
                    required={false}
                    type="password"
                  />
                </Card.Body>
              </Card>

              <div className="d-grid gap-2 col-6 mx-auto mt-4">
                <Button
                  variant="secondary"
                  type="reset"
                  onClick={() => {
                    reset();
                    setError("");
                    setAlert("");
                  }}
                >
                  Reset
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  disabled={!formState.isDirty}
                >
                  Save
                </Button>
              </div>
            </form>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Container>
  );
}

export default Account;
