import { faLock, faSignature, faUser } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../components/AuthContext";
import useTitle from "../utils/useTitle";
import { capitalize } from "../utils/helpers";

function SignUp() {
  useTitle("Sign Up");
  const [username, setUserName] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [name, setName] = useState<string>();
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>();
  const [errors, setErrors] = useState<string[]>([]);
  const navigate = useNavigate();
  const [state, dispatch] = useContext(AuthContext);

  const apiUrl = process.env.REACT_APP_API_URL;

  const validate = () => {
    if (name === undefined || name === "") {
      setErrors(["Name must exist"]);
      return false;
    }

    if (username === undefined || username === "") {
      setErrors(["Email must exist"]);
      return false;
    }

    if (password === undefined || password === "") {
      setErrors(["Password must exist"]);
      return false;
    }

    if (password.length < 6) {
      setErrors(["Password must be at least 6 characters"]);
      return false;
    }

    if (passwordConfirmation === undefined || passwordConfirmation === "") {
      setErrors(["Password Confirmation must exist"]);
      return false;
    }

    if (password !== passwordConfirmation) {
      setErrors(["Password doesn't match confirmation"]);
      return false;
    }
    return true;
  };

  const createUser = async (
    username: string | undefined,
    password: string | undefined,
    name: string | undefined
  ) => {
    return await fetch(`${apiUrl}/users/new`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: username,
        password,
        name,
      }),
    }).then((data) => data.json());
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    setErrors([]);

    e.preventDefault();

    const validation = validate();
    if (!validation) {
      return false;
    }

    try {
      const result = await createUser(username, password, name);

      if (result.error) {
        setErrors(
          result.error.errors.map((e: { message: string }) =>
            capitalize(e.message)
          )
        );
        return false;
      }

      if (!result.data) {
        setErrors(["No Data in result"]);
        return false;
      }

      dispatch({
        loggedIn: true,
        user: {
          id: result.data.user.id,
          email: result.data.user.email,
          name: result.data.user.name,
          providerId: result.data.user.providerId,
          createdAt: new Date(result.data.user.createdAt),
        },
      });

      navigate("/");
      return true;
    } catch (error: any) {
      setErrors(["Connection error"]);
      return false;
    }
  };

  useEffect(() => {
    if (state?.loggedIn) navigate("/");
  }, [state, navigate]);

  return (
    <div className="container">
      <div className="utility-form">
        <div className="text-center mb-4">
          <h3>Create Account</h3>
          <p className="text-muted">Start doing things in an instant</p>
        </div>
        <form
          onSubmit={(e) => {
            return handleSubmit(e);
          }}
        >
          {errors && errors.length > 0 && (
            <div className="alert alert-danger" role="alert">
              <ul className="mb-0">
                {errors.map((e: string) => (
                  <li key={e}>{e}</li>
                ))}
              </ul>
            </div>
          )}
          <div className="input-group mb-3">
            <span className="input-group-text">
              <FontAwesomeIcon fixedWidth={true} icon={faSignature} />
            </span>
            <input
              className="form-control border-start-0 ps-1"
              id="name"
              placeholder="Display Name"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <div className="input-group">
              <span className="input-group-text">
                <FontAwesomeIcon fixedWidth={true} icon={faUser} />
              </span>
              <input
                type="email"
                className="form-control border-start-0 ps-1"
                placeholder="Email address"
                id="email"
                autoComplete="email"
                aria-describedby="emailHelp"
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>
            <small id="emailHelp" className="text-muted ">
              We'll never share your email with anyone else.
            </small>
          </div>
          <div className="input-group mb-3">
            <span className="input-group-text">
              <FontAwesomeIcon fixedWidth={true} icon={faLock} />
            </span>
            <input
              type="password"
              className="form-control border-start-0 ps-1"
              placeholder="Password"
              autoComplete="new-password"
              id="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="input-group mb-3">
            <span className="input-group-text">
              <FontAwesomeIcon fixedWidth={true} icon={faLock} />
            </span>
            <input
              type="password"
              className="form-control border-start-0 ps-1"
              placeholder="Password Confirmation"
              autoComplete="new-password"
              id="password-confirmation"
              onChange={(e) => setPasswordConfirmation(e.target.value)}
            />
          </div>
          <div className="d-grid col-6 mx-auto">
            <button type="submit" className="btn btn-primary">
              Sign Up
            </button>
          </div>
        </form>
        <div className="text-center mt-2">
          <small className="text-muted">
            Already have an account? <Link to="/login">Login</Link>
          </small>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
