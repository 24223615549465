import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

import { Container, Row, Col } from "react-bootstrap";
import classNames from "classnames";

import Home from "./screens/Home";
import About from "./screens/About";
import Account from "./screens/Account";
import Login from "./screens/Login";
import SignUp from "./screens/SignUp";
import Calendar from "./screens/Calendar";
import Dashboard from "./screens/Dashboard";
import Contact from "./screens/Contact";
import Subscriptions from "./screens/Subscriptions";
import Subscription from "./screens/Subscription";
import MainNav from "./components/MainNav";
import Sidebar from "./components/Sidebar";
import Footer from "./components/Footer";
import SubscriptionForm from "./components/SubscriptionForm";
import useWindowDimensions from "./utils/useWindowDimensions";

function App() {
  const { width } = useWindowDimensions();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggle = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    if (width < 768) {
      setSidebarOpen(false);
    }
  }, [width]);

  return (
    <Container
      fluid
      className={classNames("wrapper", {
        toggled: sidebarOpen,
      })}
    >
      <Row className="flex-nowrap">
        <Sidebar isOpen={sidebarOpen} toggle={toggle} />
        <Col className="main-content px-0 d-flex flex-column">
          <header>
            <MainNav toggle={toggle} />
          </header>
          <main className="flex-shrink-0 pt-3">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="about" element={<About />} />
              <Route path="contact" element={<Contact />} />
              <Route path="login" element={<Login />} />
              <Route path="signup" element={<SignUp />} />
              <Route path="account" element={<Account />} />
              <Route
                path="subscriptions/:id/edit"
                element={<SubscriptionForm title="Edit Subscription" />}
              />
              <Route
                path="subscriptions/new"
                element={<SubscriptionForm title="New Subscription" />}
              />
              <Route path="subscriptions/:id" element={<Subscription />} />
              <Route path="subscriptions" element={<Subscriptions />} />
              <Route path="calendar" element={<Calendar />} />
              <Route path="dashboard" element={<Dashboard />} />
            </Routes>
          </main>
          <Footer />
        </Col>
      </Row>
    </Container>
  );
}

export default App;
