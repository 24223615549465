import { useContext, useState } from "react";

import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";
import { Button, Container, Modal } from "react-bootstrap";

import { Subscription } from "./Subscriptions";
import { getDate } from "../utils/dateHelpers";
import { AuthContext } from "../components/AuthContext";
import SubscriptionDetails from "../components/SubscriptionDetails";
import useTitle from "../utils/useTitle";
import { useApi } from "../utils/useApi";

const locales = {
  "en-US": enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const SubscriptionCalendar = () => {
  useTitle("Calendar");
  const [state] = useContext(AuthContext);
  const { data: subscriptions, loading } = useApi<Subscription[]>({
    params: {
      url: "/subscriptions",
    },
    processData: (data) => {
      return data.data.subscriptions;
    },
  });
  const [selectedSubscription, setSelectedSubscription] = useState<
    Subscription | undefined
  >(undefined);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  if (!state.loggedIn) {
    return <Container>You are not logged in</Container>;
  }

  if (loading) {
    return <Container>Loading...</Container>;
  }

  return (
    <>
      <Container>
        <Calendar
          views={["month"]}
          localizer={localizer}
          events={subscriptions?.map((sub) => {
            return {
              title: sub.name,
              start: sub.nextDueDate ? getDate(sub.nextDueDate) : undefined,
              end: sub.nextDueDate ? getDate(sub.nextDueDate) : undefined,
              allDay: true,
              resource: sub,
            };
          })}
          onSelectEvent={(event) => {
            setSelectedSubscription(event.resource);
            handleShow();
          }}
          style={{ minHeight: "500px" }}
        />
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Subscription Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SubscriptionDetails subscription={selectedSubscription} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SubscriptionCalendar;
