import React from "react";
import { Container } from "react-bootstrap";
import useTitle from "../utils/useTitle";

export default function About() {
  useTitle("About");
  return (
    <Container>
      <h1>About</h1>
      <p>
        After a long day at work, we're tired and stressed. Those feelings
        usually bring about the feeling of laziness. This is when we begin to
        ignore our subscriptions and feel like we can't be bothered to
        unsubscribe from stuff that we know we don't need or want anymore. The
        problem with that mindset is that it's not just one subscription that's
        bothering us, it's many subscriptions. Thankfully, WhatSubscription? is
        here to make your life easier!
      </p>

      <p>
        Here at <strong>WhatSubscription?</strong> we pride ourselves on helping
        you keep track of your various subscriptions. We are a small team of
        developers who love creating software.
      </p>

      <p>
        So what exactly can you track here on WhatSubscription? We support any
        type of subscription, like magazines, services, software, etc. You name
        it, we should be able to track it. If we can't for some reason, we want
        to hear about it!
      </p>
    </Container>
  );
}
