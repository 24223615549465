import React from "react";
import { Container } from "react-bootstrap";

function Footer() {
  return (
    <footer className="mt-auto py-2">
      <Container className="text-center text-muted">
        &copy; {new Date().getFullYear()} WhatSubscription?{" "}
        <small>{process.env.REACT_APP_VERSION}</small>
      </Container>
    </footer>
  );
}

export default Footer;
