import {
  faAlignJustify,
  faSignOut,
  faUser,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { Button, Container, Navbar, NavDropdown } from "react-bootstrap";
import Gravatar from "react-gravatar";
import { Link, useNavigate } from "react-router-dom";

import { AuthContext } from "./AuthContext";
import noUser from "../assets/no-user.png";

function MainNav({ toggle }: { toggle: () => void }) {
  const navigate = useNavigate();
  const [state, dispatch] = useContext(AuthContext);

  const signOut = () => {
    dispatch({
      loggedIn: false,
      user: null,
    });
    navigate("/");
  };

  return (
    <Navbar bg="dark" variant="dark">
      <Container fluid className="py-2">
        <Button variant="outline-primary" onClick={toggle}>
          <FontAwesomeIcon icon={faAlignJustify} />
        </Button>

        <NavDropdown
          id="account-dropdown"
          aria-label="Account Dropdown"
          title={
            state.loggedIn ? (
              <Gravatar
                email={state.user?.email}
                size={40}
                className="rounded"
              />
            ) : (
              <img
                alt="Not Logged In"
                className="rounded p-0"
                src={noUser}
                width={40}
                height={40}
              />
            )
          }
          align="end"
        >
          {state.loggedIn ? (
            <>
              <NavDropdown.Header>
                Welcome {state.user?.name}
              </NavDropdown.Header>
              <NavDropdown.Item as={Link} to="/account">
                <FontAwesomeIcon className="me-2" icon={faUser} />
                Account
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Button}
                className="btn-link"
                onClick={() => signOut()}
              >
                <FontAwesomeIcon className="me-2" icon={faSignOut} />
                Sign Out
              </NavDropdown.Item>
            </>
          ) : (
            <>
              <NavDropdown.Item href="/login">Login</NavDropdown.Item>
              <NavDropdown.Item href="/signup">Sign Up</NavDropdown.Item>
            </>
          )}
        </NavDropdown>
      </Container>
    </Navbar>
  );
}

export default MainNav;
