import React from "react";
import { Alert, Button, Container, Form } from "react-bootstrap";

import Loader from "../components/Loader";
import useTitle from "../utils/useTitle";

function Contact() {
  useTitle("Contact");
  const [message, setMessage] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);
    await fetch(form.action, {
      method: "post",
      body: new URLSearchParams([...(formData.entries() as any)]),
    });

    setMessage("Thanks for your message!");
    form.reset();
    setIsLoading(false);
  };

  return (
    <Container>
      <h1>Contact</h1>

      <Form
        action="https://api.post2api.com/f/1a74c323-e565-406c-ae98-088872b03e78"
        method="post"
        onSubmit={handleSubmit}
      >
        {message && (
          <Alert variant="success" dismissible onClose={() => setMessage("")}>
            {message}
          </Alert>
        )}

        <Form.Group className="mb-2">
          <Form.Label htmlFor="name">Name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            id="name"
            placeholder="Enter your name"
            required={true}
          />
        </Form.Group>

        <Form.Group className="d-none mb-3">
          <Form.Label htmlFor="url">Url</Form.Label>
          <Form.Control
            type="url"
            name="url"
            id="url"
            placeholder="Enter your url"
          />
        </Form.Group>

        <Form.Group className="mb-2">
          <Form.Label htmlFor="email">Email</Form.Label>
          <Form.Control
            type="email"
            name="email"
            id="email"
            placeholder="Enter your email"
            required={true}
          />
          <small className="text-muted">
            We'll never share your email with anyone else.
          </small>
        </Form.Group>

        <Form.Group className="mb-2">
          <Form.Label htmlFor="body">
            Let us know what you're thinking
          </Form.Label>
          <Form.Control
            as="textarea"
            id="body"
            name="body"
            rows={5}
            required={true}
          />
        </Form.Group>

        <Button variant="primary" type="submit" className="my-2">
          Send
        </Button>
      </Form>
      {isLoading && <Loader />}
    </Container>
  );
}

export default Contact;
