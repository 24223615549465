import React, { useReducer, useMemo, ReactNode, useEffect } from "react";
export type User = {
  id: string;
  password?: string;
  passwordConfirmation?: string;
  email: string;
  name: string;
  providerId?: string;
  createdAt: Date;
};
export type State = {
  loggedIn: boolean | false;
  user: Partial<User> | null;
};
const initialState: State = JSON.parse(
  (localStorage.getItem("auth") as string) || "{}"
);
export type IAuthContext = [State, React.Dispatch<Partial<State>>];

const initialContext: IAuthContext = [{ ...initialState }, () => {}];

export const AuthContext = React.createContext<IAuthContext>(initialContext);

const updater = (state: State, update: Partial<State>): State => {
  const user = update.user ? { ...state.user, ...update.user } : null;
  const result = { ...state, ...update, user };
  return result;
};

type Props = {
  children: ReactNode[] | ReactNode;
};

export function AuthController(props: Props) {
  const [authState, updateAuth] = useReducer(updater, initialState);

  useEffect(() => {
    localStorage.setItem("auth", JSON.stringify(authState));
  }, [authState]);

  const value = useMemo(() => [authState, updateAuth], [authState]) as [
    State,
    React.Dispatch<Partial<State>>
  ];

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
}
