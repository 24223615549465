import React, { useContext, useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { add, sub } from "date-fns";

import { Frequency, Subscription } from "../screens/Subscriptions";
import { AuthContext } from "./AuthContext";
import Input from "./Input";
import TextArea from "./TextArea";
import Select from "./Select";
import DatePicker from "./DatePicker";
import { getDate } from "../utils/dateHelpers";
import useSortableData from "../utils/useSortableData";
import useTitle from "../utils/useTitle";
import { useApi } from "../utils/useApi";
import callSubscriptionApi from "../utils/callSubscriptionApi";

type Props = {
  title: string;
};

function SubscriptionForm({ title }: Props) {
  useTitle(title);
  const navigate = useNavigate();
  const [state, dispatch] = useContext(AuthContext);
  const { id } = useParams();
  const { data: frequencies, loading } = useApi<Frequency[]>({
    params: {
      url: "/frequencies",
    },
    processData: (data) => {
      return data.data.frequencies.map(
        (f: any) => new Frequency(f.id, f.name, f.code, f.order)
      );
    },
  });
  const { items } = useSortableData(frequencies, {
    key: "order",
    direction: "ascending",
    type: "number",
  });
  const {
    register,
    formState: { errors: subscriptionErrors },
    handleSubmit,
    reset,
    formState,
    getValues,
    setValue,
    watch,
  } = useForm<Subscription>({
    defaultValues: {
      name: undefined,
      description: undefined,
      frequencyId: undefined,
      frequencyTime: undefined,
      startDate: undefined,
      nextDueDate: undefined,
    },
  });
  const startDateWatchedValue = watch("startDate");
  const nextDueDateWatchedValue = watch("nextDueDate");
  const frequencyWatchedValue = watch("frequencyId");
  const frequencyTimeWatchedValue = watch("frequencyTime");

  const createSubscription = async (subscription: Subscription) => {
    try {
      const result = await callSubscriptionApi(
        {
          url: `/subscriptions`,
          method: "POST",
          data: subscription,
        },
        state,
        dispatch,
        navigate
      );
      return result;
    } catch (error) {
      throw new Error("Subscription creation failed");
    }
  };

  const updateSubscription = async (subscription: Subscription) => {
    try {
      const result = await callSubscriptionApi(
        {
          url: `/subscriptions/${id}`,
          method: "PATCH",
          data: subscription,
        },
        state,
        dispatch,
        navigate
      );
      return result;
    } catch (error) {
      throw new Error("Subscription update failed");
    }
  };

  const onSubmit: SubmitHandler<Subscription> = async (results) => {
    const modelToSave = {
      ...results,
      userId: state.user!.id,
    };

    try {
      if (modelToSave?.id) {
        await updateSubscription({
          ...modelToSave,
        });
        reset();
        navigate(`/subscriptions/${modelToSave?.id}`);
      } else {
        const newResult = await createSubscription({
          ...modelToSave,
        });
        reset({});
        navigate(`/subscriptions/${newResult.id}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onStartDateChange = (date: Date) => {
    const subscription = getValues();
    if (!frequencies) return;
    if (subscription.frequencyTime && subscription.frequencyTime <= 0) {
      return;
    }

    const frequency = frequencies.find(
      (f) => f.id === subscription.frequencyId
    );

    if (!frequency) {
      return;
    }

    const frequencyName = frequency.getShortName();

    const nextDueDate = add(date, {
      [frequencyName]: subscription.frequencyTime,
    });

    setValue("startDate", getDate(date), {
      shouldDirty: true,
      shouldTouch: true,
    });
    setValue("nextDueDate", getDate(nextDueDate), {
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const onNextDueDateChange = (date: Date) => {
    const subscription = getValues();
    if (!frequencies) return;
    if (subscription.frequencyTime && subscription.frequencyTime <= 0) {
      return;
    }

    const frequency = frequencies.find(
      (f) => f.code === subscription.frequency?.code
    );

    if (!frequency) {
      return;
    }

    const frequencyName = frequency.getShortName();
    const startDate = sub(date, {
      [frequencyName]: subscription.frequencyTime,
    });

    setValue("startDate", getDate(startDate), {
      shouldDirty: true,
      shouldTouch: true,
    });
    setValue("nextDueDate", getDate(date), {
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  // Subscription loading
  useEffect(() => {
    if (!state.loggedIn) return;

    const getSubscription = async () => {
      if (!id) return;
      try {
        const result = await callSubscriptionApi(
          {
            url: `/subscriptions/${id}`,
          },
          state,
          dispatch,
          navigate
        );
        reset(result as Subscription);
      } catch (error) {
        console.log(error);
      }
    };
    // verify that the frequencies have been loaded before loading the subscription
    if (!loading) {
      getSubscription();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  // Set start date or next due date if one is not set
  useEffect(() => {
    if (!frequencies) return;
    const subscription = getValues();

    if (!subscription.frequencyTime) return;

    if (subscription.startDate && subscription.nextDueDate) {
      return;
    }

    const frequency = frequencies.find(
      (f) => f.id === subscription.frequencyId
    );
    if (!frequency) {
      return;
    }
    const frequencyName = frequency.getShortName();
    // If start date is set, set next due date
    if (!subscription.startDate && subscription.nextDueDate) {
      const startDate = sub(getDate(subscription.nextDueDate), {
        [frequencyName]: subscription.frequencyTime,
      });
      setValue("startDate", getDate(startDate), {
        shouldDirty: true,
        shouldTouch: true,
      });
      // If next due date is set, set start date
    } else if (subscription.startDate && !subscription.nextDueDate) {
      const nextDueDate = add(getDate(subscription.startDate), {
        [frequencyName]: subscription.frequencyTime,
      });
      setValue("nextDueDate", getDate(nextDueDate), {
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  }, [
    frequencyTimeWatchedValue,
    frequencyWatchedValue,
    frequencies,
    setValue,
    getValues,
  ]);

  // Change the next due date if the frequency or time changes
  useEffect(() => {
    if (!frequencies) return;
    const subscription = getValues();

    const frequency = frequencies.find((f) => f.id === frequencyWatchedValue);
    if (!frequency) {
      return;
    }
    const frequencyName = frequency.getShortName();

    if (!subscription.startDate) {
      return;
    }
    const nextDueDate = add(getDate(subscription.startDate), {
      [frequencyName]: frequencyTimeWatchedValue,
    });

    // don't bubble this because it causes a dirty form when loading
    // it fires when loading the subscription
    setValue("nextDueDate", getDate(nextDueDate));
  }, [
    frequencies,
    setValue,
    frequencyWatchedValue,
    frequencyTimeWatchedValue,
    getValues,
  ]);

  if (!state.loggedIn) {
    return <Container>You are not logged in</Container>;
  }

  return (
    <Container>
      <h1>{title}</h1>
      <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
        <Input<Subscription>
          id="name"
          label="Name"
          register={register}
          required={true}
          invalid={!!subscriptionErrors.name}
        />
        <TextArea<Subscription>
          id="description"
          label="Description"
          register={register}
          required={false}
          invalid={!!subscriptionErrors.description}
        />
        <Input<Subscription>
          id="price"
          label="Price"
          register={register}
          required={false}
          invalid={!!subscriptionErrors.price}
          type="number"
        />
        <Input<Subscription>
          id="company"
          label="Company"
          register={register}
          required={false}
          invalid={!!subscriptionErrors.company}
        />
        <div className="d-flex">
          <Input<Subscription>
            id="frequencyTime"
            className="w-50"
            label="Every"
            register={register}
            required={true}
            validation={{
              min: 1,
            }}
            errorMessage="is required and must be greater than 0"
            invalid={!!subscriptionErrors.frequencyTime}
            type="number"
            onChange={(e) => {
              if (e.target.value === "") {
                return;
              }
              setValue("frequencyTime", Number(e.target.value), {
                shouldDirty: true,
                shouldTouch: true,
              });
            }}
          />
          <Select<Subscription>
            id="frequencyId"
            className="w-50"
            label="Time"
            register={register}
            required={true}
            invalid={!!subscriptionErrors.frequencyId}
            options={
              items?.map((f) => ({
                key: f.id,
                value: f.name,
              })) ?? []
            }
            onChange={(id) => {
              const frequency = frequencies?.find((f) => f.id === id);
              if (!frequency) return;
              setValue("frequencyId", frequency.id, {
                shouldDirty: true,
                shouldTouch: true,
              });
            }}
          />
        </div>
        <DatePicker<Subscription>
          id="startDate"
          label="Start Date"
          register={register}
          watchedValue={startDateWatchedValue}
          required={false}
          inputContainerClassName="pb-5"
          invalid={!!subscriptionErrors.startDate}
          helpText="You can just enter one date and the other will be calculated providing 'Every' and 'Time' are filled in."
          onDayChange={(date) => {
            // force the start date to be set
            setValue("startDate", date, {
              shouldDirty: true,
              shouldTouch: true,
            });
            onStartDateChange(date);
          }}
        />
        <DatePicker<Subscription>
          id="nextDueDate"
          label="Next Due Date"
          register={register}
          watchedValue={nextDueDateWatchedValue}
          required={false}
          inputContainerClassName="pb-5"
          invalid={!!subscriptionErrors.nextDueDate}
          onDayChange={(date) => {
            // force the next due date to be set
            setValue("nextDueDate", date, {
              shouldDirty: true,
              shouldTouch: true,
            });
            onNextDueDateChange(date);
          }}
        />

        <TextArea<Subscription>
          id="notes"
          label="Notes"
          register={register}
          required={false}
          height={150}
          invalid={!!subscriptionErrors.description}
        />
        <div className="d-grid gap-2 col-6 mx-auto mt-4">
          <Button type="submit" variant="primary" disabled={!formState.isDirty}>
            Save
          </Button>
        </div>
      </form>
    </Container>
  );
}

export default SubscriptionForm;
