import dateFnsFormat from "date-fns/format";

export const getDate = (ISODate: Date) => {
  return new Date(new Date(ISODate).toISOString().slice(0, -1));
};
export const dateOverdue = (ISODate?: Date) => {
  if (!ISODate) {
    return false;
  }
  const now = new Date();

  const date = getDate(ISODate);
  return date < now;
};

export const DATEFORMAT = "yyyy-MM-dd";

export const formatDate = (date: Date, format: string, locale: any) => {
  return dateFnsFormat(date, format, { locale });
};
