import classNames from "classnames";
import React from "react";
import { FieldValues, Path, UseFormRegister } from "react-hook-form";

type InputProps<T extends FieldValues> = {
  id: Path<T>;
  label: string;
  register: UseFormRegister<T>;
  required: boolean;
  autoComplete?: string;
  defaultValue?: string;
  invalid?: boolean;
  helpText?: string;
  type?: string;
  className?: string;
  validation?: any;
  errorMessage?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export default class Input<T extends FieldValues> extends React.Component<
  InputProps<T>
> {
  render() {
    const { onChange, ...rest } = this.props.register(this.props.id, {
      required: this.props.required,
      ...this.props.validation,
    });

    return (
      <div className={classNames("mb-3", this.props.className)}>
        <div className="form-floating">
          <input
            type={this.props.type || "text"}
            className={`form-control ${this.props.invalid ? "is-invalid" : ""}`}
            id={this.props.id}
            {...rest}
            placeholder={this.props.label}
            defaultValue={this.props.defaultValue}
            autoComplete={this.props.autoComplete || "off"}
            onChange={(e) => {
              if (this.props.onChange) {
                this.props.onChange(e);
              }
              onChange(e); // React Hook Form
            }}
          />
          {this.props.invalid && (
            <div className="invalid-feedback">
              {this.props.label} {this.props.errorMessage || "is required"}
            </div>
          )}
          <label htmlFor={this.props.id}>{this.props.label}</label>
        </div>
        {this.props.helpText && (
          <small className="form-text text-muted">{this.props.helpText}</small>
        )}
      </div>
    );
  }
}
