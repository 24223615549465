import {
  faCalendarCheck,
  faCalendarStar,
  faCalendarWeek,
  faCheck,
  faCircle,
  faExclamationTriangle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getWeek, getYear } from "date-fns";
import { useContext } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";

import { AuthContext } from "../components/AuthContext";
import { getDate } from "../utils/dateHelpers";
import { useApi } from "../utils/useApi";
import useTitle from "../utils/useTitle";

export class Subscription {
  id!: string;
  name!: string;
  description?: string;
  price?: number;
  company?: string;
  startDate?: Date;
  nextDueDate?: Date;
  terminationDate?: Date;
  notes?: string;
  frequencyTime?: number;
  frequency?: Frequency;
}
export class Frequency {
  id!: string;
  name!: string;
  code!: string;
}

function Dashboard() {
  useTitle("Dashboard");
  const { data: subscriptions } = useApi<Subscription[]>({
    params: {
      url: "/subscriptions",
    },
    processData: (data) => {
      return data.data.subscriptions;
    },
  });
  const [state] = useContext(AuthContext);

  const formatter = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: "USD",
  });

  const subscriptionsForMonth = () => {
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    const subs = subscriptions?.filter(
      (subscription) =>
        subscription.nextDueDate &&
        getDate(subscription.nextDueDate) <= lastDay &&
        getDate(subscription.nextDueDate) >= firstDay
    );
    return subs || [];
  };

  const subscriptionsForWeek = () => {
    const today = new Date();
    const week = getWeek(today);
    const year = today.getFullYear();

    const subs = subscriptions?.filter(
      (subscription) =>
        subscription.nextDueDate &&
        getWeek(getDate(subscription.nextDueDate)) === week &&
        getYear(getDate(subscription.nextDueDate)) >= year
    );
    return subs || [];
  };

  const overDueSubscriptions = () => {
    const subs = subscriptions?.filter(
      (subscription) =>
        subscription.nextDueDate &&
        getDate(subscription.nextDueDate) < new Date()
    );
    return subs || [];
  };

  const futureSubscriptions = () => {
    const subs = subscriptions?.filter(
      (subscription) =>
        subscription.nextDueDate &&
        getDate(subscription.nextDueDate) > new Date()
    );
    subs?.sort((a, b) => {
      if (a.nextDueDate && b.nextDueDate) {
        return (
          getDate(a.nextDueDate).getTime() - getDate(b.nextDueDate).getTime()
        );
      }
      return 0;
    });
    return subs || [];
  };

  const upcomingRenewals = () => {
    const subs = futureSubscriptions().filter((_, index) => index < 3);
    return subs;
  };

  if (!state.loggedIn) {
    return <Container>You are not logged in</Container>;
  }

  return (
    <Container>
      <h1>Dashboard</h1>
      <h2 className="bg-secondary text-white p-3 rounded">
        Subscription Totals
      </h2>
      <Row>
        <Col md={4} className="mb-3">
          <Card>
            <Card.Header>Total</Card.Header>
            <Card.Body>
              <div className="d-flex align-items-center">
                <span className="fa-layers fa-fw fa-3x me-2">
                  <FontAwesomeIcon icon={faCircle} color="#A88ADD" />
                  <FontAwesomeIcon
                    icon={faCalendarStar}
                    inverse
                    transform="shrink-6"
                  />
                </span>

                <div className="fs-2">
                  {subscriptions?.length?.toLocaleString()}
                </div>
              </div>

              <small className="text-muted d-flex justify-content-center">
                Cost:{" "}
                {subscriptions &&
                  formatter.format(
                    subscriptions
                      .map((sub) => Number(sub.price || 0))
                      .reduce((a, b) => a + b, 0)
                  )}
              </small>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-3">
          <Card>
            <Card.Header>This Month</Card.Header>
            <Card.Body>
              <div className="d-flex">
                <span className="fa-layers fa-fw fa-3x me-2">
                  <FontAwesomeIcon icon={faCircle} color="#FCC900" />
                  <FontAwesomeIcon
                    icon={faCalendarCheck}
                    inverse
                    transform="shrink-6"
                  />
                </span>

                <div className="fs-2">
                  {subscriptionsForMonth().length.toLocaleString()}
                </div>
              </div>

              <small className="text-muted d-flex justify-content-center">
                Cost:{" "}
                {formatter.format(
                  subscriptionsForMonth()
                    .map((sub) => Number(sub.price || 0))
                    .reduce((a, b) => a + b, 0)
                )}
              </small>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-3">
          <Card>
            <Card.Header>This Week</Card.Header>
            <Card.Body>
              <div className="d-flex">
                <span className="fa-layers fa-fw fa-3x me-2">
                  <FontAwesomeIcon icon={faCircle} color="#0AC3AB" />
                  <FontAwesomeIcon
                    icon={faCalendarWeek}
                    inverse
                    transform="shrink-6"
                  />
                </span>

                <div className="fs-2">
                  {subscriptionsForWeek().length.toLocaleString()}
                </div>
              </div>

              <small className="text-muted d-flex justify-content-center">
                Cost:{" "}
                {formatter.format(
                  subscriptionsForWeek()
                    .map((sub) => Number(sub.price || 0))
                    .reduce((a, b) => a + b, 0)
                )}
              </small>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <h2 className="bg-secondary text-white p-3 rounded">Upcoming Renewals</h2>
      <Row>
        {upcomingRenewals().map((subscription) => (
          <Col md={4} className="mb-3" key={subscription.id}>
            <Card>
              <Card.Header>{subscription.name}</Card.Header>
              <Card.Body>
                <div className="d-flex">
                  <span className="fa-layers fa-fw fa-3x me-2">
                    <FontAwesomeIcon icon={faCircle} color="#0AC3AB" />
                    <FontAwesomeIcon
                      icon={faCheck}
                      inverse
                      transform="shrink-6"
                    />
                  </span>

                  <div className="d-flex flex-column">
                    <small>
                      Cost:{" "}
                      {subscription.price
                        ? formatter.format(subscription.price || 0)
                        : "Unknown"}
                    </small>
                    <small>
                      Due{": "}
                      {getDate(subscription.nextDueDate!).toLocaleDateString()}
                    </small>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      {overDueSubscriptions().length > 0 && (
        <>
          <h2 className="bg-danger text-white p-3 rounded">
            Overdue Subscriptions
          </h2>
          {overDueSubscriptions().map((subscription) => (
            <Card key={subscription.id} className="mb-3">
              <Card.Header>
                {" "}
                Was due on{" "}
                {getDate(subscription.nextDueDate!).toLocaleDateString()}
              </Card.Header>
              <Card.Body>
                <div className="d-flex align-items-center">
                  <span className="fa-layers fa-fw fa-3x me-2">
                    <FontAwesomeIcon icon={faCircle} color="red" />
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      inverse
                      transform="shrink-7"
                    />
                  </span>

                  <div className="fs-2">
                    {subscription.name}
                    {": "}
                    {formatter.format(subscription.price || 0)}
                  </div>
                </div>
              </Card.Body>
            </Card>
          ))}
        </>
      )}
    </Container>
  );
}

export default Dashboard;
