import { format } from "date-fns";
import React from "react";
import { Subscription } from "../screens/Subscriptions";
import { getDate } from "../utils/dateHelpers";

type Props = {
  subscription: Subscription | undefined;
};

function SubscriptionDetails({ subscription }: Props) {
  if (!subscription) {
    return <dl></dl>;
  }

  return (
    <dl>
      <dt>Name</dt>
      <dd>{subscription?.name}</dd>
      <dt>Price</dt>
      <dd>{subscription?.price || "N/A"}</dd>
      <dt>Description</dt>
      <dd>{subscription?.description || "N/A"}</dd>
      <dt>Company</dt>
      <dd>{subscription?.company || "N/A"}</dd>
      <dt>Start Date</dt>
      <dd>
        {subscription?.startDate
          ? format(getDate(subscription?.startDate), "MM/dd/yyyy")
          : "N/A"}
      </dd>
      <dt>Next Due Date</dt>
      <dd>
        {subscription?.nextDueDate
          ? format(getDate(subscription?.nextDueDate), "MM/dd/yyyy")
          : "N/A"}
      </dd>
      <dt>Frequency</dt>
      <dd>
        Every {subscription?.frequencyTime} {subscription?.frequency?.name}
      </dd>
      <dt>Notes</dt>
      <dd>{subscription?.notes || "N/A"}</dd>
    </dl>
  );
}

export default SubscriptionDetails;
