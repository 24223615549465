import React from "react";

export default function useSortableData(
  items: any[] | undefined,
  config: { key: string; direction: string; type: string } | null = null
) {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedItems = React.useMemo(() => {
    if (!items) {
      return [];
    }
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (sortConfig.type === "string") {
          if (sortConfig.direction === "ascending") {
            return a[sortConfig.key].localeCompare(b[sortConfig.key]);
          } else {
            return b[sortConfig.key].localeCompare(a[sortConfig.key]);
          }
        } else if (sortConfig.type === "number") {
          if (sortConfig.direction === "ascending") {
            return a[sortConfig.key] - b[sortConfig.key];
          } else {
            return b[sortConfig.key] - a[sortConfig.key];
          }
        } else if (sortConfig.type === "date") {
          if (sortConfig.direction === "ascending") {
            return (
              new Date(a[sortConfig.key]).getTime() -
              new Date(b[sortConfig.key]).getTime()
            );
          } else {
            return (
              new Date(b[sortConfig.key]).getTime() -
              new Date(a[sortConfig.key]).getTime()
            );
          }
        } else {
          return 0;
        }
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key: string, type: string = "string") => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction, type });
  };

  return { items: sortedItems, requestSort, sortConfig };
}
